import { isSodimacStandalone, isTottusStandalone, isWhitelabel } from '@digital-retail/store-manager';
import { cx } from 'cvb';
import PropTypes from 'prop-types';
import React from 'react';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import noop from '../../../utils/noop';
import { buttonStyle } from './button.style';

const Button = ({
  children,
  onClick,
  type,
  isHighlighted,
  disabled,
  id,
  size,
  strech,
  letterSpace,
  appCtx: { store },
}) => {
  const idAttr = id ? { id } : {};
  const isTottusSA = isTottusStandalone(store);
  const isSodimacSA = isSodimacStandalone(store);
  const isWhitelabelSite = isWhitelabel({ store });

  return (
    <button
      disabled={disabled}
      className={cx(
        'button',
        type && `button-${type}`,
        isHighlighted && 'button-bold',
        size && `button-${type}-${size}`,
        strech && `button-${type}-strech-${strech}`,
        letterSpace && `button-${type}-letter-${letterSpace}`,
        isSodimacSA && 'sodimac-theme',
        isTottusSA && 'tottus-theme',
        isWhitelabelSite && 'whitelabel-theme'
      )}
      type="button"
      onClick={onClick}
      {...idAttr}
    >
      {children}
      <style jsx>{buttonStyle}</style>
    </button>
  );
};

Button.defaultProps = {
  children: null,
  type: 'primary',
  onClick: noop,
  isHighlighted: false,
  disabled: false,
  id: '',
  size: '',
  strech: '',
  letterSpace: '',
};

Button.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'quatinery',
    'link',
    'secondary-link',
    'secondary-link-mkp',
    'tertiary-link',
    '',
    'outline',
    'sort-button',
    'button-secondary-no-width',
    'mkp-primary',
    'mkp-primary-medium',
    'mkp-primary-xtra-large',
    'mkp-secondary',
    'mkp-primary primary-theme',
    'mkp-primary-medium primary-theme',
    'mkp-primary-xtra-large primary-theme',
    'mkp-secondary primary-theme',
  ]),
  isHighlighted: PropTypes.bool,
  id: PropTypes.string,
  size: PropTypes.oneOf(['medium', 'large', 'xtra-large', 'small']),
  strech: PropTypes.oneOf(['full', 'auto']),
  letterSpace: PropTypes.oneOf(['small', 'regular']),
  appCtx: PropTypes.object.isRequired,
};

export default withApplicationContext(Button);
