import { isTottusStandalone } from '@digital-retail/store-manager';
import { cx } from 'cvb';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import noop from '../../../utils/noop';
import styles from './Switch.style';

const Switch = (props) => {
  const {
    isChecked,
    onChange,
    manageState,
    id,
    appCtx: { store },
    type,
  } = props;
  const [isSelected, setSelected] = useState(isChecked);
  const isTottusSA = isTottusStandalone(store);
  const isPopularBrand = type === 'popular-brand';
  const handleOnChange = () => {
    if (manageState) {
      setSelected(!isSelected);
    }
    onChange({
      isSelected: !isSelected,
    });
  };
  useEffect(() => {
    setSelected(isChecked);
  }, [isChecked, props]);

  return (
    <label htmlFor={`chk-${id}`} className={`switch ${type}`}>
      <input id={`chk-${id}`} type="checkbox" checked={isSelected} onChange={handleOnChange} />
      <span
        className={cx(
          'slider round',
          isTottusSA && 'tottus',
          isPopularBrand && 'popular-brand',
          isSelected && 'is-selected'
        )}
      />
      <style jsx>{styles}</style>
    </label>
  );
};

Switch.defaultProps = {
  isChecked: false,
  onChange: noop,
  manageState: true,
  type: undefined,
};

Switch.propTypes = {
  id: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
  manageState: PropTypes.bool,
  appCtx: PropTypes.object.isRequired,
  type: PropTypes.string,
};

export default withApplicationContext(Switch);
