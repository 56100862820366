import { cx } from 'cvb';
import PropTypes from 'prop-types';
import React from 'react';
import { withApplicationContext } from '../../utils/ApplicationContext';
import DynamicFacetsBarStyles from './DynamicFacetsBar.style';

const DynamicFacetsBar = ({ groups, onSelect, store, selectedKey, isOpen, showPlus, setIsFiltersMenuVisible }) => {
  const isActive = (group) => group?.active || group.facets.some((g) => g.values.some((v) => v.selected));
  const styles = DynamicFacetsBarStyles(store);
  return (
    <div className="dynamic-facets-bar">
      <div className="dynamic-facets-bar-scroll">
        {groups.map((group) => (
          <button
            type="button"
            key={group.name}
            className={cx(
              'dynamic-facets-bar-button',
              isActive(group) && 'active',
              group.key === 'event' && 'event-button'
            )}
            onClick={() => onSelect(group)}
          >
            {group.icon && <i className={`dynamic-facets-bar-icon ${group.icon}`} />}
            <span>{group.name}</span>
            {!group.skipDrawer && <i className="arrow-down" />}
            {false &&
              group.key !== 'event' &&
              (selectedKey === group.key && isOpen ? (
                <i className="csicon-arrow_up_small" />
              ) : (
                <i className="csicon-arrow_down_small" />
              ))}
          </button>
        ))}
        {showPlus && (
          <button
            type="button"
            key="plus"
            className="dynamic-facets-bar-button"
            onClick={() => {
              setIsFiltersMenuVisible(true);
            }}
          >
            +
          </button>
        )}
      </div>
      <style jsx>{styles}</style>
    </div>
  );
};

DynamicFacetsBar.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({})),
  onSelect: PropTypes.func,
  store: PropTypes.string,
  selectedKey: PropTypes.string,
  isOpen: PropTypes.bool,
  showPlus: PropTypes.bool.isRequired,
  setIsFiltersMenuVisible: PropTypes.func.isRequired,
};

DynamicFacetsBar.defaultProps = {
  groups: [],
  onSelect: () => {},
  selectedKey: '',
  isOpen: false,
  store: '',
};

export default withApplicationContext(DynamicFacetsBar);
