import { isTottusStandalone } from '@digital-retail/store-manager';
import { cx } from 'cvb';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import React from 'react';
import { withLabels } from '../../utils/LabelsContext';
import _ from '../../utils/LodashImports';
import { withMyLists } from '../../utils/MyListsContext';
import styles from './MyLists.style';
import useMyLists from './useMyLists';

const MyListModal = dynamic(() => import('./Modal/MyListsModal'), {
  ssr: false,
  loading: () => '',
});

const MyLists = ({ appCtx, myLists, product, labels, className, isPDPpage, handleClick }) => {
  const isMyListsEnabled = _.get(appCtx, 'siteConfig.toggles.isMyListsEnabled', false);
  const {
    createList,
    lists,
    modalVisible,
    setModalVisible,
    onListChange,
    onSaveLists,
    validateList,
    error,
    loading,
    showMyLists,
    setShowForm,
    isSaveButtonEnabled,
    showForm,
    productIsInAnyList,
  } = useMyLists({
    myLists,
    product,
    isPDPpage,
    labels,
  });
  const l = {
    defaultMyList: labels.DAFAULT_LIST_TYPE || 'WISH_LIST',
    save: labels.SAVE || 'Guardar',
    inputPlaceHolder: labels.MY_LIST_INPUT_PLACEHOLDER || 'Ej. Compra del mes',
    formTitle: labels.INPUT_NEW_LIST_NAME || 'Ingresa un nombre para tu lista',
    myLists: labels.MY_LISTS || 'Mis listas',
    createNewList: labels.CREATE_A_NEW_LIST_MY_LISTS || 'Crear una nueva lista',
    addToList: labels.ADD_TO_A_LIST_MY_LISTS || 'Agrega a una lista',
  };

  return (
    isMyListsEnabled && (
      <div
        className={`container-my-lists ${className}`}
        role="presentation"
        onClick={(e) => {
          e.stopPropagation();
          if (!e.target.id.includes('list-item-')) {
            e.preventDefault();
          }
        }}
      >
        {showMyLists && (
          <button
            id={`ml-icon-${product.productId}`}
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleClick(true);
              setModalVisible(!modalVisible);
            }}
          >
            <i
              className={cx(
                'csicon-my-list',
                productIsInAnyList && 'active',
                isTottusStandalone(appCtx.store) && 'tottus'
              )}
            />
          </button>
        )}
        <MyListModal
          myLists={lists}
          toggleState={{
            visible: modalVisible,
            setVisible: setModalVisible,
          }}
          formState={{
            visible: showForm,
            setVisible: setShowForm,
          }}
          createList={createList}
          onSaveLists={onSaveLists}
          appCtx={appCtx}
          onListChange={onListChange}
          validateList={validateList}
          error={error}
          loading={loading}
          labels={l}
          isSaveButtonEnabled={isSaveButtonEnabled}
        />
        <style jsx>{styles}</style>
      </div>
    )
  );
};

MyLists.propTypes = {
  appCtx: PropTypes.object.isRequired,
  myLists: PropTypes.object.isRequired,
  isPDPpage: PropTypes.bool,
  product: PropTypes.objectOf({
    productId: PropTypes.string.isRequired,
    variantId: PropTypes.string.isRequired,
    offeringId: PropTypes.string.isRequired,
    sellerId: PropTypes.string,
  }).isRequired,
  labels: PropTypes.object,
  className: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
};

MyLists.defaultProps = {
  labels: {},
  className: '',
  isPDPpage: false,
};

export { MyLists };
export default withLabels(withMyLists(MyLists));
