import { isWhitelabel } from '@digital-retail/store-manager';
import { cx } from 'cvb';
import PropTypes from 'prop-types';
import React from 'react';
import BodyCopy from '../ui/BodyCopy/BodyCopy';
import badgesStyles from './Badges.style';
import stickersStyles from './Stickers.style';

const Badges = ({ badgeItems, deviceType, layout, isSticker, isEventSticker, store }) => {
  const isWhitelabelSite = isWhitelabel({ store });
  const getBadgeSize = () => {
    if (deviceType === 'mobile') {
      return layout.indexOf('PDP') !== -1 ? 'copy4' : 'copy7';
    }
    return 'copy8';
  };

  const getBadgeStyles = (badge) => {
    return isWhitelabelSite
      ? { backgroundColor: 'var(--secondary-color)', color: '#fff' }
      : {
          backgroundColor: badge.styles.backgroundColor,
          color: badge.styles.textColor,
        };
  };

  return (
    <div
      className={cx(
        layout !== 'LIST' && isSticker && 'meatstickers',
        layout === 'LIST' && !isEventSticker && `pod-badges pod-badges-${layout}`,
        (isSticker || isEventSticker) && 'sticker-group',
        isEventSticker && layout === 'LIST' && deviceType === 'mobile' && 'list-mobile-event',
        isEventSticker && layout === 'LIST' && deviceType === 'desktop' && 'list-desktop-event'
      )}
    >
      {badgeItems?.map((badge) => (
        <BodyCopy size={getBadgeSize()} key={badge.label} isHighlighted>
          {() => {
            const newBadgeLabel = badge?.label?.replace('<b>', '<b style="font-weight:700">');

            return (
              newBadgeLabel && (
                <span
                  id={`testId-Pod-badges-${badge.type}`}
                  className={`pod-badges-item-${layout} pod-badges-item ${
                    badge.type === 'event' ? 'event-sticker-bold' : ''
                  }`}
                  style={getBadgeStyles(badge)}
                  dangerouslySetInnerHTML={{ __html: newBadgeLabel }}
                />
              )
            );
          }}
        </BodyCopy>
      ))}
      <style jsx>{badgesStyles}</style>
      <style jsx>{stickersStyles}</style>
    </div>
  );
};

Badges.propTypes = {
  badgeItems: PropTypes.array,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
  layout: PropTypes.string,
  isSticker: PropTypes.bool,
  isEventSticker: PropTypes.bool,
  store: PropTypes.string,
};

Badges.defaultProps = {
  badgeItems: [],
  layout: '',
  isSticker: false,
  isEventSticker: false,
  store: '',
};

export default Badges;
