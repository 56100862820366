import { cx } from 'cvb';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { resetDigitalData } from './Common/digitalData';
import { facetClick } from './FacetEvents';
import StoresModal from './ShippingFacet/StoresModal/StoresModal';
import ShippingFacetsBarStyles from './ShippingFacetsBar.style';

const ShippingFacetsBar = ({
  shippingFacetGroup,
  freeShippingFacet,
  onSelect,
  store,
  dataMethods,
  appCtx,
  selectedOptions,
}) => {
  const [isStoreModalVisible, setIsStoreModalVisible] = useState(false);
  const [selectedStoreCount, setSelectedStoreCount] = useState(0);

  const styles = ShippingFacetsBarStyles(store);
  const { baseFacetsURL, changeURL } = dataMethods;
  const { textDictionary, configurations } = appCtx?.siteConfig ?? {};
  const { BUY_AT_STORE_LABEL = 'Disponible en tienda' } = textDictionary ?? {};
  const {
    shippingFacetOrder = [
      'f.shipping.cc_same_day_politicalId',
      'f.shipping.hd_same_day_politicalId',
      'f.derived.variant.freeShipping',
      'f.shipping.hd_next_day_politicalId',
      'f.shipping.cc_next_day_politicalId',
      'f.shipping.bas_stores',
    ],
  } = configurations ?? {};

  const singleSelectFacets = useMemo(
    () => [
      ...(shippingFacetGroup?.values?.filter(({ facetType }) => !/(.bas_|.pks_)/.test(facetType)) ?? []),
      ...(freeShippingFacet?.values ?? []),
    ],
    [shippingFacetGroup, freeShippingFacet]
  );

  const basFacets = useMemo(
    () => shippingFacetGroup?.values?.filter(({ facetType }) => /(.bas_)/.test(facetType)) ?? [],
    [shippingFacetGroup]
  );

  useEffect(() => {
    setSelectedStoreCount(basFacets.filter(({ selected }) => selected).length);
  }, [basFacets]);

  const onFacetChange = (facet, resetDD = false) => {
    facetClick();
    const urls = [baseFacetsURL].filter(Boolean);
    if (facet.url) {
      const cleanURL = facet.url
        .split('&')
        .filter((u) => !u.includes('facetSelected'))
        .join('&');
      urls.push('facetSelected=true', cleanURL);
    }
    changeURL(urls.join('&'));
    onSelect();
    if (resetDD) resetDigitalData();
  };

  const handleFacetChange = (facet) => {
    onFacetChange({ ...facet, selected: !facet.selected });
  };

  const getOrderedFacets = useCallback(
    (facets) => {
      const selectedFacets = facets
        .filter(({ selected }) => selected)
        .sort((a, b) => b.isMenu - a.isMenu)
        .map(({ facetType }) => facetType);
      return [...new Set([...selectedFacets, ...shippingFacetOrder, ...facets.map(({ facetType }) => facetType)])];
    },
    [shippingFacetOrder]
  );

  const mapChips = () => {
    const chips = singleSelectFacets.map((facet) => ({
      ...facet,
      clickHandler: () => handleFacetChange(facet),
      icon: facet.facetType === 'f.shipping.cc_same_day_politicalId' ? 'flash-icon' : '',
      isMenu: false,
    }));

    if (basFacets.length > 0) {
      chips.push({
        facetType: 'f.shipping.bas_stores',
        title: BUY_AT_STORE_LABEL,
        clickHandler: () => setIsStoreModalVisible(true),
        isMenu: true,
        selected: selectedStoreCount > 0,
        count: selectedStoreCount,
      });
    }
    return chips;
  };

  const renderChips = () => {
    const chips = mapChips();
    const orderedFacets = getOrderedFacets(chips);
    const sortedChips = orderedFacets
      .map((facetType) => chips.find((facet) => facet.facetType === facetType))
      .filter(Boolean);

    return sortedChips.map(({ facetType, selected, title, isMenu, icon, clickHandler, count }) => (
      <>
        <button
          key={facetType}
          id={title}
          type="button"
          className={cx('shipping-facets-bar-button', selected && 'active')}
          onClick={clickHandler}
          aria-label={title}
        >
          {icon && <i className={`shipping-facets-bar-icon ${icon}`} />}
          <span>{title}</span>
          {isMenu && <span>({count})</span>}
          {isMenu && <i className="arrow-down" />}
        </button>
        <style jsx>{styles}</style>
      </>
    ));
  };

  return (
    <div className="shipping-facets-bar">
      <div className="shipping-facets-bar-scroll">{renderChips()}</div>
      {isStoreModalVisible && (
        <StoresModal
          facetValue="f.shipping.bas_stores"
          selectedOptions={selectedOptions}
          initialStoresList={basFacets}
          showModal={isStoreModalVisible}
          setShowModal={setIsStoreModalVisible}
          setSelectedCount={setSelectedStoreCount}
          textDictionary={textDictionary}
          baseFacetsURL={baseFacetsURL}
          changeURL={changeURL}
        />
      )}
      <style jsx>{styles}</style>
    </div>
  );
};

ShippingFacetsBar.propTypes = {
  shippingFacetGroup: PropTypes.shape({ values: PropTypes.array.isRequired }),
  freeShippingFacet: PropTypes.shape({ values: PropTypes.array }),
  onSelect: PropTypes.func,
  selectedOptions: PropTypes.object,
  store: PropTypes.string,
  dataMethods: PropTypes.shape({
    baseFacetsURL: PropTypes.string,
    changeURL: PropTypes.func.isRequired,
  }).isRequired,
  appCtx: PropTypes.shape({
    siteConfig: PropTypes.shape({
      textDictionary: PropTypes.object,
      configurations: PropTypes.object,
    }),
  }).isRequired,
};

ShippingFacetsBar.defaultProps = {
  shippingFacetGroup: { values: [] },
  freeShippingFacet: { values: [] },
  selectedOptions: {},
  onSelect: () => {},
  store: '',
};

export default withApplicationContext(ShippingFacetsBar);
